// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { cssBundleHref } from '@remix-run/css-bundle';
import { json } from '@remix-run/node';
import { Outlet } from '@remix-run/react';
import i18next from '~/i18next.server';
import { getUserFromSession } from '~/api/utilities/authSession.server';
import { getFlashMessage } from '~/api/utilities/flashMessage.server';
import { commitSession } from '~/api/utilities/session.server';
import keenSliderCss from 'keen-slider/keen-slider.min.css';
import slickStyles from 'slick-carousel/slick/slick.css';
import appStyles from './app.css';
import contentStyles from './assets/styles/contentstyles.css';
import Document from '~/components/_layout/_common/_document';
import { useToast } from './_hooks/useApp';

// Registering Style Links
export const links = () => [...(cssBundleHref ? [{
  rel: 'stylesheet',
  href: cssBundleHref
}] : []), {
  rel: 'stylesheet',
  href: appStyles
}, {
  rel: 'stylesheet',
  href: keenSliderCss
}, {
  rel: 'stylesheet',
  href: slickStyles
}, {
  rel: 'stylesheet',
  href: contentStyles
}, {
  rel: 'preconnect',
  href: 'https://fonts.googleapis.com'
}, {
  rel: 'preconnect',
  href: 'https://fonts.gstatic.com',
  crossOrigin: 'anonymous'
}, {
  rel: 'stylesheet',
  href: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap'
}];

// Root Loader
export async function loader({
  request
}) {
  const {
    message: toastMessage,
    session
  } = await getFlashMessage(request);
  let locale = await i18next.getLocale(request);
  const user = await getUserFromSession(request);
  const isLoggedIn = !!user?.id;
  const isProduction = process.env.ENV_MODE === 'production';
  return json({
    toastMessage,
    user,
    isLoggedIn,
    isProduction,
    locale
  }, {
    headers: {
      'Set-Cookie': await commitSession(session)
    }
  });
}
// Root Meta
export const meta = () => {
  return [{
    title: 'ESG and Sustainability Performance Management Solutions'
  }, {
    name: 'description',
    content: 'ESG and Sustainability Performance Management Solutions, Track, optimize, and report on environmental, social, and governance performance for a sustainable future. Elevate your brand, and attract investors.'
  }];
};
export default function App() {
  _s();
  // Registering Toaster
  // In The Root
  useToast();
  return <Document>
      <Outlet />
    </Document>;
}
_s(App, "zmshFnTy2h/BI3RcMmgy8WlZc1c=", false, function () {
  return [useToast];
});
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;